<template>
  <div class="card card-custom card-stretch gutter-b text-center">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Haftalık Ödev Yükü Dağılımı</span>
      </h3>
    </div>
    <div class="chart card-body pt-3 pb-0">
      <apex-chart
        v-if="shown"
        :options="chartOptions"
        :series="series"
        type="bar"
        height="350"
        :width="chartWidth"
      ></apex-chart>
      <b-alert v-else show variant="info">Şubeye Atanmış Ödev Bulunamadı..</b-alert>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['studentid'],
  name: 'student-test-load-widget',
  data() {
    return {
      shown: false,
      init: false,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          width: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0
          }
        },
        xaxis: {
          type: 'number',
          categories: []
        },
        fill: {
          opacity: 1
        }
      },
      series: []
    }
  },
  computed: {
    dynamicWidth() {
      return this.series[0].data.length * 100
    },

    chartWidth() {
      return this.dynamicWidth < window.innerWidth ? '100%' : this.dynamicWidth
    }
  },
  watch: {
    deep: true,
    immediate: true,
    studentid(val) {
      if (val > 0) {
        this.getData(this.studentid)
      }
    }
  },
  methods: {
    getData(studentId) {
      this.init = true
      ApiService.setHeader()
      ApiService.post('api/Classbranch/reports/studenttestload', {
        StudentId: studentId //buranın değişmesi lazım ama bu sayfa nerede kullanılıyor bilemedim
      })
        .then((data) => {
          var testloadlist = data.data
          if (testloadlist.length == 0) {
            this.series = []
            this.shown = false
            return
          }
          var lessons = []
          for (let i = 0; i < testloadlist.length; i++) {
            const hour = testloadlist[i]
            if (lessons.indexOf(hour.ClassBranchName) == -1) lessons.push(hour.ClassBranchName)
          }
          this.$nextTick(() => {
            this.series = lessons.map(function (s) {
              return {
                name: s,
                data: testloadlist
                  .filter((d) => d.ClassBranchName == s)
                  .map(function (f) {
                    return f.QuestionCount
                  })
              }
            })
            var cats = []
            for (let i = 0; i < testloadlist.length; i++) {
              const element = testloadlist[i]
              if (cats.indexOf(element.WeekNumber) == -1) cats.push(element.WeekNumber)
            }

            this.chartOptions.chart.width = this.chartWidth
            this.chartOptions.xaxis.categories = cats
            this.shown = true
          })
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>

<style>
.chart {
  overflow: auto;
}
</style>
