<template>
  <div class="text-center mt-10">
    <div class="row">
      <div class="col-lg-12">
        <StudentTestLoadWidget :studentid="studentId"></StudentTestLoadWidget>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import StudentTestLoadWidget from '@/view/pages/report_widgets/StudentTestLoadWidget.vue'

export default {
  name: 'raporlar-ekrani',
  components: { StudentTestLoadWidget },
  data() {
    return {
      studentId: 0
    }
  },
  mounted() {
    this.getUserFromApi()
  },
  methods: {
    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
        .then((data) => {
          this.studentId = data.data.Id
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
